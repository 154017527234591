.combo {
  position: relative;

  &__input-wrapper {
    position: relative;
    text-align: left;
    cursor: default;
    width: 100%;
    background: #f2f2f2;
    border-radius: 4px;
    outline: none;
    @media (min-width: 640px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  &__input {
    color: #111827;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 1.25rem;
    width: 100%;
    border-style: none;
    background: transparent;
    width: 100%;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    outline: none;
  }

  &__btn-group {
    all: unset;
    display: flex;
    gap: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding-right: 0.5rem;
    align-items: center;
  }

  &__btn-icon {
    color: #9ca3af;
    width: 1.25rem;
    height: 1.25rem;
  }

  &__list {
    overflow-y: auto;
    max-height: 200px;
    position: absolute;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-top: 0.25rem;
    background-color: #ffffff;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    border-radius: 0.375rem;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--ring-offset-width))
      var(--ring-color);
    --ring-color: #000000;
    --ring-opacity: 0.05;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);

    @media (min-width: 640px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  &__empty {
    position: relative;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #374151;
    cursor: default;
    user-select: none;
  }

  &__option {
    position: relative;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    cursor: default;
    user-select: none;
    color: #111827;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      display: block;
    }

    &--selected span {
      font-weight: bold;
    }

    &--active {
      background-color: #f3f4f6;
    }

    &__transition {
      &-leave {
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition-duration: 100ms;
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      }
    }

    .opacity-100 {
      opacity: 1;
    }

    .opacity-0 {
      opacity: 0;
    }
  }

  &__highlight {
    background-color: rgb(5 214 120 / 30%);
  }
}
