.customer-list {
  display: flex;
  flex-direction: column;

  &__title {
    flex: 0 0 auto;
    padding: 8px 0;
    // font-size: 1.2rem;
    // border-bottom: 1px solid rgba(black, 0.2);
  }

  &__search {
    flex: 0 0 auto;
    padding-block-start: 8px;
    // border-top: 1px solid rgba(black, 0.2);

    input {
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      padding: 0.5em;
      outline: none;
      background-color: #f5f5f5;
    }
  }

  &__list {
    flex: 1 1 auto;
  }

  ul {
    list-style: none;
    display: grid;
    grid-gap: 16px;
    // padding: 8px;
  }

  li {
    background-color: white;
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    // padding: 8px;
  }
}
