.widget {
  &__header {
    //background: var(--chakra-colors-brand-light-txt);
    //color: hsl(33, 100%, 98%);
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    //box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 20;
    gap: 0.75rem;
  }

  &__actions {
    display: flex;
    gap: 0.5em;
    align-items: center;
    color: #fff;
  }

  &__btn {
    svg {
      width: 1.2em;
      height: 1.2em;
    }
  }

  &__title,
  &__btn {
    font-size: 2rem;
    color: inherit;
  }

  &__title {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 1;
    text-decoration: underline;
    //letter-spacing: 0.05em;
  }

  &__btn {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }

  &__content {
    z-index: 19;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    // background-color: #e2e8f0;
    &--overflow {
      padding-bottom: 1.5rem;

      & > * {
        overflow-x: auto;
        max-height: 160px;
        padding: 1.5rem 1.25rem 0 1.25rem;
        margin-top: -0.75rem;

        @media (min-width: 48rem) {
          max-height: max(160px, 50vh);
        }
      }
    }

    &:not(.widget__content--overflow) {
      padding: 1.5rem 1.25rem 1.5rem 1.25rem;
      margin-top: -0.75rem;
    }
  }

  &__container {
    & > * + * {
      margin-top: 4rem;
    }
  }
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  z-index: 9999;
  width: 2em;
  height: 2em;
  background-color: var(--color-mm-green);
  border-radius: 50%;

  &:hover {
    background-color: var(--color-mm-green-active);
  }

  svg {
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
  }

  &--isLoading svg {
    animation: animate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  &--delete {
    border: 2px var(--color-mm-red) solid;
    border-radius: 50%;
    transition: transform ease-in-out 0.15s;
    will-change: transform;
    background: transparent;
    transform: scale3d(0.85, 0.85, 0.85);

    svg {
      fill: var(--color-mm-red);
    }

    &:hover {
      transform: scale3d(1, 1, 1);
      background: transparent;
    }
  }
}

.post-entry {
  border-bottom: 1px solid var(--color-mm-gray-50);
  padding-bottom: 3rem;

  &__content {
    // border-radius: 5px;

    background-color: #fff;
    width: 100%;
    // padding: 1rem;
    // border-top: 1px solid greenyellow;
    // box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
    // max-height: 450px;
    // overflow-x: hidden;
    p {
      font-size: 1.5rem;
    }
  }

  &__images {
    /*--images-count: 2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 0.5rem;
    background-color: var(--color-bg);
    margin-top: 1rem;*/
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    padding: 2rem 0;
    gap: 1rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    // height: 250px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__loadMore {
    padding: 0.5em;
    border-radius: 3px;
    background-color: #fff;
    box-shadow:
      0 20px 25px -5px rgb(0 0 0 / 10%),
      0 10px 10px -5px rgb(0 0 0 / 4%);
    font-size: 0.7em;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
  }

  &__img {
    display: block;
    position: relative;
    scroll-snap-align: start;

    img {
      max-width: none !important;
      width: 225px;
      height: auto;
    }

    figcaption {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(57, 57, 57, 0.702);
      top: 0;
      left: 0;
      transform: scale(0);
      transition: all 0.2s 0.1s ease-in-out;
      color: #fff;
      border-radius: 5px;
      /* center overlay text */
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
    }

    &:hover {
      figcaption {
        transform: scale(1);
      }
    }
  }

  &__header {
    font-size: 1.5rem;

    p {
      color: var(--color-mm-green);
    }
  }

  &__title {
    text-decoration: underline;
  }

  &__footer {
    margin-top: 0.5rem;
    text-align: right;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1.5rem;
    color: var(--color-gray-2);
  }
}

.fullscreen-gallery {
  position: fixed;
  inset: 0;
  background-color: rgba(26, 26, 26, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 10000000;

  &__image {
    position: relative;

    img {
      margin: 0;
      padding: 0;
      display: block;
      max-width: 90vw;
      max-height: 80vh;
      height: auto;
    }

    figcaption {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(57, 57, 57, 0.702);
      top: 0;
      left: 0;
      transform: scale(0);
      transition: all 0.2s 0.1s ease-in-out;
      color: #fff;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 0.5rem;
    }

    &:hover {
      figcaption {
        transform: scale(1);
      }
    }
  }
}

@keyframes animate {
  0% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(0deg);
  }
  100% {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(360deg);
  }
}

.signature {
  &__preview {
  }

  pre {
    font-size: 0.6em;
    padding-bottom: 8px;
  }

  &__actions {
    display: grid;
    gap: 8px;
  }

  input {
    border: 1px solid black;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.21;
    border-radius: 4px;
    transition: background-color 0.4s;
  }

  .btn {
    display: block;
    width: 100%;
  }
}
