/* Typography */

$sans: 'Theinhardt', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$sans2: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: Georgia, serif;
$mono: Menlo, Courier, monospace;

@mixin pagetitle {
  font-family: $sans;
  font-weight: 300;
  font-size: 56px;
  line-height: 1.1;

  @include respond-to('s') {
    font-size: 72px;
  }

  @include respond-to('m') {
    font-size: 96px;
  }

  @include respond-to('l') {
    font-size: 120px;
  }
}

@mixin title-1 {
  font-family: $sans;
  font-weight: 400;
  font-size: 34px;
  line-height: 1.1;

  @include respond-to('m') {
    font-size: 52px;
  }

  @include respond-to('l') {
    font-size: 76px;
  }
}

@mixin title-1-s {
  font-family: $sans;
  font-weight: 400;
  font-size: 32px;
  line-height: 1.1;

  @include respond-to('m') {
    font-size: 40px;
  }
}

@mixin title-2 {
  font-family: $sans;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.25;

  @include respond-to('s') {
    font-size: 34px;
  }

  @include respond-to('l') {
    font-size: 52px;
  }
}

@mixin title-2-3 {
  font-family: $sans;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.25;

  @include respond-to('s') {
    font-size: 34px;
  }

  @include respond-to('l') {
    font-size: 43px;
  }
}

@mixin title-3 {
  font-family: $sans;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;

  @include respond-to('s') {
    font-size: 32px;
  }
}

@mixin title-4 {
  font-family: $sans;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;
}

@mixin headline {
  font-family: $sans;
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;

  @include respond-to('s') {
    font-size: 21px;
  }

  @include respond-to('m') {
    font-size: 21px;
  }
}

@mixin subline {
  font-family: $sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;

  @include respond-to('s') {
    font-size: 19px;
  }

  @include respond-to('m') {
    font-size: 20px;
  }

  @include respond-to('l') {
    font-size: 21px;
  }
}

@mixin text {
  font-family: $sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;

  @include respond-to('s') {
    font-size: 19px;
  }

  @include respond-to('m') {
    font-size: 20px;
  }

  @include respond-to('l') {
    font-size: 21px;
  }
}

@mixin teaser {
  font-family: $sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;

  @include respond-to('s') {
    font-size: 19px;
  }

  @include respond-to('m') {
    font-size: 20px;
  }

  @include respond-to('l') {
    font-size: 21px;
  }
}

@mixin meta {
  font-family: $sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;

  @include respond-to('s') {
    font-size: 18px;
  }

  @include respond-to('m') {
    font-size: 18px;
  }
}

@mixin micro {
  font-family: $sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;

  @include respond-to('m') {
    font-size: 16px;
  }
}

@mixin quote {
  font-family: $sans;
  font-weight: 400;
  font-size: 43px;
  line-height: 1;

  @include respond-to('m') {
    font-size: 86px;
  }
}

@mixin brand {
  font-family: $sans;
  font-weight: 400;
  font-size: 21px;
  line-height: 1;
}

@mixin navigation {
  font-family: $sans;
  font-weight: 400;
  font-size: 21px;
  line-height: 1;
}

@mixin navigation-small {
  font-family: $sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  text-align: left;

  @include respond-to('m') {
    text-align: right;
  }
}

@mixin navigation-highlight {
  font-family: $sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
}

@mixin button-medium {
  font-family: $sans;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;

  @include respond-to('s') {
    font-size: 34px;
  }
}

@mixin label {
  font-family: $sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  @include respond-to('l') {
    font-size: 18px;
    line-height: 22px;
  }
}

@mixin dark-on-light {
  -webkit-font-smoothing: antialiased;
}

.dark-on-light {
  @include dark-on-light;
}

@mixin font-light {
  font-weight: 300;
}

@mixin slider-indicator {
  font-family: $sans;
  font-weight: 400;
  font-size: 48px;
  line-height: 1;

  @include respond-to('m') {
    font-size: 56px;
    line-height: 1;
  }
  @include respond-to('l') {
    font-size: 64px;
    line-height: 1;
  }
}
