/*.dashboard {
  display: grid;
  grid-template-columns: repeat(4, calc((100% - 3 * 20px)/4));
  grid-gap: 20px;
  &__widgets{
    display: flex;
    justify-content: flex-start;
    grid-column: 1 / -1;
  }
  &__content {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}
@media (min-width: 48rem) {
  .dashboard {
    grid-template-columns: repeat(12, calc((100% - 11 * 20px)/12));
    grid-gap: 20px;
    &__widgets{
      grid-column: 8 / -1;
      flex-direction: column;
      gap: 0.75rem;
      align-items: flex-end;
    }
    &__content {
      grid-column: 1 / 8;
    }
  }
}*/

.dashboard {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;

  &__sidebar {
    order: -1;
  }

  @media (min-width: 64rem) {
    grid-template-columns: 65% 35%;

    &__sidebar {
      order: 2;
    }
  }

  @media (min-width: 80rem) {
    // grid-template-columns: 25% 50% 25%;
  }
}

.dash-header {
  background: transparent; //#fafafa;
  border-bottom: 1px solid #f5f5f5;
  margin-top: -2rem;
  margin-inline: -3rem;
  margin-bottom: 2rem;

  &__wrapper {
    margin-inline: auto;
    padding-block: 2rem;
    padding-inline: 3rem 3rem;
  }

  &__head {
    display: flex;
    align-items: center;
    @media (min-width: 640px) {
      justify-content: space-between;
      gap: 1rem;
    }
    margin-bottom: 2rem;
  }

  &__left {
    display: flex;
    flex: 1 1 0%;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    @media (min-width: 640px) {
      justify-content: flex-end;
    }
  }

  &__group {
    display: flex;
    gap: 1rem;
  }

  &__usr {
    display: flex;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    align-items: center;
    border-radius: 0.5rem;

    img {
      object-fit: cover;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 9999px;
    }
  }

  &__info {
    display: none;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: left;
    margin-inline-start: 0.5rem;
    @media (min-width: 640px) {
      display: block;
    }

    & > strong {
      display: block;
    }

    & > span {
      color: rgb(107 114 128 / var(--tw-text-opacity));
    }
  }

  &__title,
  h1,
  &__sub-title,
  p {
    max-width: 64ch;
  }

  &__title,
  h1 {
    color: #111827;
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 400;

    @media (min-width: 640px) {
      font-size: 1.875rem;
    }
  }

  &__sub-title,
  p {
    margin-top: 0.75rem;
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.5;
    @media (min-width: 640px) {
      font-size: 1.175rem;
    }
  }

  &__btn {
    display: block;
    padding: 0.625rem;
    background-color: #ffffff;
    color: #4b5563;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    :hover {
      color: #374151;
    }
  }

  &__content {
    display: grid;
    align-items: center;
    gap: 1rem;
    @media (min-width: 640px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
