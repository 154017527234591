dialog {
  border: none !important;
  border-radius: 8px;
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  max-width: 640px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 99999;
}

.dialog {
  position: relative;
  z-index: 10;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &__overlay {
    overflow-y: auto;
    position: fixed;
    inset: 0;
  }

  &__wrapper {
    display: flex;
    padding: 1rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }

  &__window {
    position: relative;
    transition-property: all;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    max-width: 28rem;

    border-radius: 8px;
    box-shadow:
      0 0 #0000,
      0 0 #0000,
      0 25px 50px -12px rgba(0, 0, 0, 0.25);
    padding: 2rem;
    background: rgba(255, 255, 255, 0.95);
  }

  &__form {
    position: relative;

    &-label {
      display: block;
      opacity: 0.5;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      padding: 0.25em 0.75em;
      margin-top: 0.5rem;
    }

    &-button {
      display: block;
      width: 100%;
      color: var(--color-mm-gray);
      padding: 0.75em 1.5em;
      border-radius: 4px;

      &.button--cta {
        background-color: #05d678;
        color: #fff;

        &:hover,
        &:focus {
          background: #00bb69;
        }
      }

      &.button--destructive {
        color: var(--color-mm-red);

        &:hover,
        &:focus {
          color: var(--color-mm-red-active);
          background-color: var(--color-mm-gray-3);
        }
      }

      &.button--cancel {
        position: absolute;
        top: -1.5rem;
        right: -1.5rem;
        width: auto;
      }
    }

    input[type='text'],
    input[type='datetime-local'],
    &-textarea {
      display: block;
      width: 100%;
      background: #f2f2f2;
      padding: 0.25em 0.75em;
      margin-bottom: 0.5em;
      border-radius: 4px;
      outline: none;
    }

    &-textarea {
      resize: none;
      padding: 0.5em 0.75em;
    }

    &-select {
      background-color: #f2f2f2;
    }

    &-actions {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
      margin: 2rem auto 0;
      max-width: 20ch;
    }
  }
}
