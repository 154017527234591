.editor {
  text-align: left;
  margin-top: 3rem;
  max-width: 640px;

  .inline {
    display: inline;
  }

  .underline {
    text-decoration: underline;
  }

  .inline.underline {
    margin-right: 1rem;
  }

  .secondary {
    color: var(--color-sub);
  }

  &--centered {
    margin: 0 auto;
  }

  &--text-centered {
    text-align: center;
    margin: 0 auto;
  }

  &--reader {
    max-width: 720px;
  }

  h1,
  .pagetitle {
    @include title-2;
  }

  h2,
  .title-1 {
    @include title-2-3;
  }

  h3,
  .title-2 {
    @include title-3;
  }

  .title-2-3 {
    @include title-2-3;
  }

  h4,
  .title-3 {
    @include title-3;
  }

  .title-4 {
    @include title-4;
  }

  h5,
  .headline {
    @include headline;
    text-decoration: underline;
  }

  h6,
  .subline {
    @include subline;
  }

  .btn--text--medium {
    @include button-medium;
  }

  p,
  .text {
    @include text;
    max-width: 60ch;
    hyphens: auto;

    &.teaser {
      @include teaser;
    }

    &.footnote {
      @include meta;
    }

    &.caption {
      @include micro;
    }
  }

  .light {
    @include font-light;
  }

  ul,
  ol {
    @include text;
  }

  table {
    width: 100%;
    margin: 1em 0 1.75em;
    @include text;

    th,
    td {
      padding: 1em 1em 1em 0;
      border-bottom: 1px solid var(--color-border);
    }

    th {
      border-bottom-width: 2px;
    }
  }

  blockquote {
    display: block;
    margin: 1em 0 1.25em 0;
    // margin: 1.25em 0 1.75em -1rem;
    // padding-left: 1rem;
    // border-left: 2px solid var(--color-border);
    @include quote;

    &.quote--simple {
      @include title-2;
    }
  }

  ul {
    margin: 1.25em 0 1.75em 1em;
    list-style-type: disc;

    li {
      margin-left: 0.5em;
      margin-bottom: 0.5rem;
    }
  }

  ol {
    margin: 1.25em 0 1.75em 1em;
    list-style-type: decimal;

    li {
      margin-left: 0.5em;
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    & + * {
      margin-top: 0.25em;
    }

    a {
      text-decoration: none;
    }
  }

  * + h1,
  * + h2,
  * + h3,
  * + h4,
  * + h5,
  * + h6 {
    margin-top: 1.25em;
  }

  p {
    & + p {
      margin-top: 1.25em;
    }
  }
}
