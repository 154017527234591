.modal-window {
  position: fixed;
  background-color: var(--color-mm-gray-50);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 0.5;
  transition: all 0.3s;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;

  &__content,
  & > div {
    width: min(100vw, 400px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: white;
  }

  &__header {
    font-weight: bold;
  }

  &__title,
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }

  &__close {
    color: #aaa;
    line-height: 50px;
    font-size: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
}
