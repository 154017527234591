#sidebar {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  z-index: 997;
  pointer-events: none;

  .sidebar__header {
    pointer-events: all;

    z-index: 1111;
    position: relative;
    color: hsl(33, 100%, 98%);
    padding: 1.25rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 0 0 0 15px;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    gap: 16px;
  }

  .sidebar__menu {
    pointer-events: all;
    z-index: 1101;
    position: relative;
    padding-top: 15px;
    margin-top: -15px;
    transform: translateX(100%);
    transition: all 150ms ease-in-out;
    background-color: #e2e8f0;
    display: grid;
    width: 100%;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-bottom-left-radius: 15px;
  }

  .sidebar__item {
    display: flex;
    justify-content: flex-start;
    gap: 32px;

    &--logout {
    }
  }

  .sidebar__link {
    width: 100%;
    display: flex;
    padding: 1.25rem;
    color: #00071d;
    align-items: center;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    line-height: 1.15;
    list-style: none;
    background-color: var(--secondary-bg);
    font-size: 22px;
    border-radius: 4px;
    gap: 16px;
    justify-content: center;

    &:hover {
      background-color: rgba(187, 187, 187, 0.5);
    }

    span {
      display: block;
    }
  }

  .sidebar__username {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.sidebar--open {
    .sidebar__menu {
      transform: translateX(0%);
    }
  }
}
