/* Variables */

/* Colors */
$black: #000;
$white: #fff;
$green: #0aa244;
$grey-light: #dedede;
$grey-dark: #888888;

$c_primary: $black;
$c_secondary: $green;
$c_tertiary: $white;

/* Element-Colors */
$c_typo_primary: #6bb5c7;
$c_typo_secondary: #f7f052;

$c_bg_primary: $white;
$c_bg_secondary: #6bb5c7;

$c_typo_invers: $c_tertiary;
$c_bg_invers: $black;
$c_bg_hero: $c_primary;
/* Breakpoints */
$breakpoints: (
  's': 640px,
  'm': 960px,
  'l': 1280px,
  'xl': 1440px,
);
/* Z-Indexes */
$z-indexes: (
  'modal': 5000,
  'dropdown': 4000,
  'default': 1,
  'below': -1,
);

:root {
  --color-bg: #ffffff;
  --color-txt: #1a1a1a;
  --color-sub: #808080;
  --color-border: #cccccc;
  --color-focus: #e6e6e6;
  --color-bg-secondary: #f2f2f2;
  --color-bg-secondary-h: #e5e5e5;
  --color-highlight: rgb(1, 255, 82);
  --color-error: rgb(254, 68, 85);

  --font-rendering: subpixel-antialiased;
  --spacer: 8;

  --color-mm-green: hsl(164, 95%, 43%);
  --color-mm-green-active: hsl(164, 95%, 43%);
  --color-mm-green-foreground: hsl(0, 0%, 100%);
  --color-mm-red: hsl(346, 75%, 63%);
  --color-mm-red-active: hsl(346, 74%, 60%);
  --color-mm-red-foreground: hsl(0, 0%, 100%);
  --color-mm-blue: hsl(222, 100%, 61%);
  --color-mm-blue-active: hsla(222, 100%, 61%, 0.84);
  --color-mm-blue-foreground: hsl(0, 0%, 100%);
  --color-mm-yellow: hsl(30, 59%, 61%);
  --color-mm-yellow-active: hsl(30, 53%, 51%);
  --color-mm-yellow-foreground: hsl(0, 0%, 100%);
  --color-mm-gray: rgba(204, 204, 204, 1);
  --color-mm-gray-50: rgba(204, 204, 204, 0.5);
  --color-mm-gray-2: rgba(187, 187, 187, 1);
  --color-mm-gray-2-50: rgba(187, 187, 187, 0.1);
  --color-mm-gray-3: rgba(242, 242, 242, 1);
  --color-backgorund: #232323;
  --color-text: #fff;
}

[data-theme='dark'] {
  --color-bg: #1a1a1a;
  --color-txt: #e6e6e6;
  --color-sub: #808080;
  --color-border: #2a2a2a;
  --color-focus: #333333;
  --color-bg-secondary: #262626;
  --color-bg-secondary-h: #323232;
  --color-highlight: rgb(1, 255, 82);
  --color-error: rgb(254, 68, 85);

  --font-rendering: antialiased;
  --spacer: 8;
}
