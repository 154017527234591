.add-projects {
  background-color: white;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  min-width: Max(320px, 80vw);
  max-width: 1024px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    'headline headline'
    'customer projects'
    'footer footer';
  z-index: 9001;

  &__headline,
  &__footer {
    padding-inline: var(--gap);
    padding-block: var(--gap);
  }

  &__headline {
    // border-bottom: 1px solid rgba(black, 0.2);
    grid-area: headline;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  &__customer {
    grid-area: customer;
    // border-right: 1px solid rgba(black, 0.2);
  }

  &__projects {
    grid-area: projects;
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    grid-template-columns: 100%;
  }

  &__title {
    padding: 8px;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(black, 0.2);
  }

  &__list {
    display: grid;
    grid-gap: var(--gap);
    grid-template-columns: repeat(auto-fit, Minmax(Min(220px, 33%), 1fr));
    padding-inline: var(--gap);
    padding-block: var(--gap);
  }

  &__edit {
    border-bottom: 1px solid rgba(black, 0.2);
  }

  &__customer,
  &__projects {
    min-height: Max(200px, 80vh);
    display: grid;

    & > * {
      align-self: stretch;
    }
  }

  &__footer {
    border-top: 1px solid rgba(black, 0.2);
    grid-area: footer;
  }
}
