.task {
  &__list {
    display: grid;
    // gap: 0.75rem;
    padding: 0;
    margin: 0;
    list-style: none;
    min-height: 120px;
  }

  &__project {
    opacity: 0.25;
  }

  &__creator {
    color: var(--color-mm-green);
  }

  &__item {
    // border-radius: 5px;
    background-color: #fff;
    width: 100%;
    padding: 1rem 0;
    // border-top: 1px solid greenyellow;
    border-bottom: 1px solid var(--color-mm-gray-50);
    // box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
    display: flex;
    align-content: flex-start;
    gap: 0.75rem;

    .task--full & {
      padding: 1.5rem 0;
      gap: 1rem;
    }

    .task--simple & {
      background-color: transparent;
      border-radius: 0;
      border: none;
      padding: 0;
    }
  }

  &__title {
    justify-self: flex-start;
    margin-left: 0;

    .task--full & {
      margin-right: auto;
      font-size: 21px;
    }

    .task--simple & {
      font-size: 18px;
    }
  }

  &__check {
    position: relative;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;

    label {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 24px;
      height: 24px;
      background: var(--color-mm-green);
      border-radius: 100%;
      cursor: pointer;
      box-shadow:
        inset var(--color-mm-green) 0 0 0 2px,
        inset white 0 0 0 4px;
      transition: box-shadow 0.1s ease-out;

      &:before {
        width: 0;
        height: 0;
      }

      &:after {
        opacity: 0;
        content: '';
        position: absolute;
        top: -2px;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 7px;
        height: 11px;
        border-right: 3px solid white;
        transform: rotate(90deg);
        transition: opacity 0.2s ease-out;
      }

      &:hover {
        box-shadow:
          inset var(--color-mm-green) 0 0 0 12px,
          inset white 0 0 0 12px;

        &:after {
          opacity: 1;
        }
      }
    }

    &--checked {
      label {
        background: transparent;
        box-shadow: none;
        animation: bounce 0.4s linear;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          background: var(--color-mm-green);
          border-radius: 100%;
        }

        &:after {
          opacity: 1;
          border-bottom: 3px solid white;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__meta {
    margin-top: 0.5rem;
    text-align: right;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1.5rem;
    color: var(--color-gray-2);

    p {
      margin-left: auto;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    gap: 0.5em;
    align-items: center;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;

    .task--full & {
      margin-left: 0;
    }
  }

  &__group-header {
    border-bottom: 2px solid #333;
    padding: 15px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem 0;
  }

  &__filter {
    background: transparent;
    color: #333;

    font-size: 1rem;
    text-transform: uppercase;
    -moz-appearance: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E') !important;
    background-repeat: no-repeat, repeat !important;
    background-position:
      right 0em top 50%,
      0 0 !important;
    background-size:
      0.65em auto,
      100% !important;
    border: none;
    border-radius: 0px;
    padding-right: 1em;
  }

  &__counter {
    border: 0;
    padding: 3px 8px;
    background: 0;
    font-size: 0.7em;
    line-height: 1;

    cursor: pointer;
    font-family: var(--font);
    //color: #8a9ca5;
    border-radius: 20px;
    background-color: #7996a5;
    color: #fff;
  }

  &__empty {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23f4f4f4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 486.463 486.463'%3e%3cpath d='M243.225 333.382c-13.6 0-25 11.4-25 25s11.4 25 25 25c13.1 0 25-11.4 24.4-24.4.6-14.3-10.7-25.6-24.4-25.6z'/%3e%3cpath d='M474.625 421.982c15.7-27.1 15.8-59.4.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4 16.3-74.9 43.4l-156.8 271.5c-15.6 27.3-15.5 59.8.3 86.9 15.6 26.8 43.5 42.9 74.7 42.9h312.8c31.3 0 59.4-16.3 75.2-43.6zm-34-19.6c-8.7 15-24.1 23.9-41.3 23.9h-312.8c-17 0-32.3-8.7-40.8-23.4-8.6-14.9-8.7-32.7-.1-47.7l156.8-271.4c8.5-14.9 23.7-23.7 40.9-23.7 17.1 0 32.4 8.9 40.9 23.8l156.7 271.4c8.4 14.6 8.3 32.2-.3 47.1z'/%3e%3cpath d='M237.025 157.882c-11.9 3.4-19.3 14.2-19.3 27.3.6 7.9 1.1 15.9 1.7 23.8 1.7 30.1 3.4 59.6 5.1 89.7.6 10.2 8.5 17.6 18.7 17.6s18.2-7.9 18.7-18.2c0-6.2 0-11.9.6-18.2 1.1-19.3 2.3-38.6 3.4-57.9.6-12.5 1.7-25 2.3-37.5 0-4.5-.6-8.5-2.3-12.5-5.1-11.2-17-16.9-28.9-14.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;

    font-size: 18px;
    background-size: 80px;
  }
}

.task__item {
  .task__actions {
    button {
      pointer-events: none;
    }
  }

  &--done {
    opacity: 0.25;
  }

  &--waiting {
    input[type='checkbox'] + label {
      box-shadow:
        inset #fac000 0 0 0 2px,
        inset white 0 0 0 4px;
    }
  }

  &:hover {
    .task__actions {
      opacity: 1;

      button {
        pointer-events: all;
      }
    }
  }

  .task__due {
    background: var(--color-mm-gray-2-50);
    border-radius: 4px;
    white-space: nowrap;
    padding: 0.1em 1em;
  }
}

.tabs {
  &__btn {
    padding: 0 0.5em;

    &--active {
      color: var(--color-mm-green);
    }
  }
}

input[type='checkbox'] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: none;
  z-index: 999;

  + label {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 100%;
    cursor: pointer;
    box-shadow:
      inset var(--color-mm-green) 0 0 0 2px,
      inset white 0 0 0 4px;
    transition: box-shadow 0.1s ease-out;

    &:before {
      width: 0;
      height: 0;
    }

    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      top: -2px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 7px;
      height: 11px;
      border-right: 3px solid white;
      transform: rotate(90deg);
      transition: opacity 0.2s ease-out;
    }

    &:hover {
      box-shadow:
        inset var(--color-mm-green) 0 0 0 12px,
        inset white 0 0 0 12px;

      &:after {
        opacity: 1;
      }
    }
  }

  &:checked {
    + label {
      background: transparent;
      box-shadow: none;
      animation: bounce 0.4s linear;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: var(--color-mm-green);
        border-radius: 100%;
      }

      &:after {
        opacity: 1;
        border-bottom: 3px solid white;
        transform: rotate(45deg);
      }
    }
  }
}

.task-filter {
  margin-top: 1rem;
  margin-bottom: 3rem;

  .kanban & {
    margin: 0 1.25rem;
  }

  &__customers,
  &__projects {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    display: flex;
    width: 100%;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }

  &__customer {
    font-weight: 400;
    opacity: 0.5;
    font-size: 16px;
    line-height: 1.2;
    color: rgba(0, 0, 0);
    max-width: 200px;
    white-space: nowrap;
    display: flex;
    padding: 0.25em 0;
    // pointer-events: none;

    span {
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }

    &--active {
      //color: var(--color-mm-green);
      text-decoration: underline;
      opacity: 0.5;
    }
  }

  &__project,
  &__date {
    color: #000000;
    // opacity: 0.5;
    font-size: 16px;
    line-height: 20px;
    max-width: 200px;
    display: flex;
    white-space: nowrap;
    padding: 0.25em 0;

    span {
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }

    &:hover {
      color: var(--color-mm-green);
      opacity: 1;
    }

    &--active {
      color: var(--color-mm-green);
      opacity: 1;

      .task-filter__project-name {
        text-decoration: underline;
      }
    }
  }
}
