.filter {
  &__title {
    border-bottom: 2px solid #333;
    padding: 15px 0 5px;
    display: flex;
    margin: 0 0 1rem 0;
    font-size: 1.3rem;

    cursor: pointer;
  }

  &__content {
    display: flex;
    max-width: 80vw;
    overflow: hidden;
    gap: 0.5rem;
    width: 0;

    &--isOpen {
      width: auto;
    }
  }

  &__group {
    display: flex;
    z-index: 19;
    gap: 0.5rem;
    padding-inline-end: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    align-content: center;

    & + & {
      border-left: 2px solid #333;
      padding-inline-start: 1rem;
    }
  }

  &__header {
    grid-column: 1 /-1;
    font-size: 1.1rem;
  }

  &__field {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &--inline {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__label {
    font-size: 0.7rem;
    line-height: 1;

    span {
      color: var(--color-mm-red);
      font-size: 0.7em;
      vertical-align: middle;
    }
  }

  &__input {
    padding: 0.5em 0.3em;
    border-radius: 0.3em;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    line-height: 1;
    background: #f5f5f5;

    &--textarea {
      min-height: 7em;
    }

    &--has-error {
      border: 1px solid var(--color-mm-red);
      color: var(--color-mm-red);
    }
  }

  &__item {
    flex: 0 0 auto;
    border: 0;
    background: 0;
    padding: 3px 3px 3px 8px;
    font-size: 0.7em;
    line-height: 1;

    cursor: pointer;
    border-radius: 20px;
    background-color: #7996a5;
    color: #fff;
    word-break: keep-all;

    span {
      border-radius: 10px;
      display: inline-block;
      height: 1em;
      aspect-ratio: 1;
      margin-left: 0.5em;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__button {
    padding: 8px 24px;
    background-color: var(--color-mm-green);
    border-radius: 4px;
    color: white;

    font-size: 14px;
    line-height: 1.21;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &[disabled],
    .disabled {
      background-color: var(--color-mm-red);
      opacity: 0.5;
      cursor: not-allowed;
    }

    & + & {
      border-left: 1px solid rgba(0 0 0 / 0.1);
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    &--single {
      border-radius: 4px;
    }
  }
}

.timeline {
  --column-width: 80px;
  --column-height: 40px;
  display: flex;
  position: relative;

  &__aside {
    flex: 0 0 175px;
    position: relative;
    z-index: 1;
    padding: calc(2 * var(--column-height)) 0 0;
  }

  &__main {
    flex: 1 1 auto;
    overflow-x: auto;
    position: relative;
    z-index: 1;

    /*
         *  STYLE 1
        */

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      width: 6px;
      background-color: #555;
    }
  }

  &__row {
    position: relative;
    display: flex;
    padding: 10px 0;
    align-items: center;
    min-height: var(--column-height);
    height: var(--column-height);
    // padding-block: 1rem;
    gap: 8px;
    z-index: 5;
    border-top: 1px solid #f2f2f2;

    span {
      font-size: 14px;
      line-height: 1.21;
    }

    &:nth-child(2n) {
    }

    &--dense {
    }

    &--me span {
      color: var(--color-mm-green);
    }

    &--hidden {
      button {
        &::before {
          background-color: rgba(0, 0, 0, 0.11);
        }

        &:hover::before {
          background-color: var(--color-mm-green);
        }
      }

      span {
        color: rgba(0, 0, 0, 0.11);
      }
    }
  }

  &__btn {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;

    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 6px;
      background-color: var(--color-mm-green);
      border-radius: 23px;
    }

    &:hover::before {
      background-color: rgba(0, 0, 0, 0.11);
      width: 12px;
      height: 12px;
    }
  }

  &__lines {
    display: grid;
    grid-template-columns: var(--column-width);
    grid-auto-columns: var(--column-width);
    grid-auto-flow: column;
    border-bottom: 1px solid #f2f2f2;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: transparent;
  }

  &__line {
    display: block;
    z-index: 3;
    border-left: 1px solid #f2f2f2;

    &--weekend {
      background-color: #fafafa;
    }

    &--today {
      &:after {
        display: block;
        width: 1px;
        content: '';
        background-color: #ff6b00;
        position: relative;
        z-index: -1;
        height: calc(100% - 1.5 * var(--column-height));
        margin: calc(1.5 * var(--column-height)) auto 0 auto;
        position: relative;
        z-index: 0;
      }
    }
  }

  &__header {
    display: grid;
    grid-template-columns: var(--column-width);
    grid-auto-columns: var(--column-width);
    grid-auto-flow: column;
    padding-top: 8px;
    left: 0px;
    z-index: 2;
    min-height: var(--column-height);
    height: var(--column-height);

    &--month {
      padding: 0;
      background-color: white;
    }
  }

  &__day,
  &__month {
    display: block;
    z-index: 4;
    padding-inline: 16px;
  }

  &__day {
    font-style: normal;

    font-size: 14px;
    line-height: 1.21;
    text-align: center;

    span {
      padding-block: 5px;
      display: block;
      border-radius: 19px;
      margin: 0 auto;
    }

    &--today {
      padding-inline: 4px;

      span {
        position: relative;
        z-index: 10;
        display: block;
        padding-inline: 13px;

        background: #ff6b00;

        color: white;
      }
    }
  }

  &__month {
    grid-column: span var(--monthLength, 1);
    text-align: left;

    font-size: 18px;
    line-height: 22px;
    background-color: white;
    border-left: 1px solid #f2f2f2;
    display: flex;
    align-content: center;

    span {
      display: block;
      margin-top: 0.55em;
      position: sticky;
      left: 0;
    }
  }

  &__rows {
    position: relative;
    border-top: 1px solid #f2f2f2;
  }

  &__entries {
    display: grid;
    grid-template-columns: var(--column-width);
    grid-auto-columns: var(--column-width);
    align-items: center;
    grid-auto-flow: column;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 0;
    min-height: var(--column-height);
    height: var(--column-height);

    &--dense {
    }
  }

  &__entry {
    grid-row: 1/1;
    text-align: left;
    font-size: 10px;
    line-height: 1;
    min-height: 6px;
    border-radius: 4px;
    margin-inline: 8px;
    color: #fff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    grid-column-start: var(--columnStart);
    grid-column-end: var(--columnEnd);
    z-index: 4;
    align-items: center;

    &--vacation {
      background: rgba(164, 239, 217, 0.8);
    }

    &--holiday {
      z-index: 5;
      background-color: #9f7aea;
    }

    &--offDay,
    &--offDay-prev,
    &--offDay-edit {
      height: 20px;
      border-radius: 20px;
      display: flex;
      align-content: center;
      padding-inline: 8px;

      &:before {
        font-size: 12px;

        content: 'Vorschau';
      }
    }

    &--offDay,
    &--offDay-prev {
      background: rgba(6, 214, 160, 0.2);
      border: 1px dashed var(--color-mm-green);

      &:before {
        color: rgba(6, 214, 160, 0.8);
      }
    }

    &--offDay-prev {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-inline-end: 0;
    }

    &--offDay-edit {
      background: rgba(55, 114, 255, 0.7);
      border: 1px dashed hsla(222, 100%, 50%, 1);
      pointer-events: none;

      &:before {
        color: white;
      }
    }

    &--editable {
    }
  }
}

.timeline__entries--special {
  .timeline__entry {
    height: 8px;
    &--vacation {
      background-color: #ed8936;
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 5px,
        rgba(255, 255, 255, 0.1) 5px,
        rgba(255, 255, 255, 0.1) 12px
      );
      cursor: pointer;
    }

    &--holiday {
      z-index: 5;
      background-color: #9f7aea;
    }
  }
}

.timeline__entries--me {
  .timeline__entry {
    &--vacation {
      background: var(--color-mm-green);
      height: 20px;
      border-radius: 20px;
    }
  }
}

.timeline-header {
  display: flex;
  justify-content: space-between;
}

.vacation__counter {
  padding: 0.25em;
  font-size: 0.75em !important;
}

.toolbar {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 800;
  --border-color: rgba(255, 255, 255, 0.3);

  .btn:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  &--open {
    .react-datepicker-wrapper {
      width: auto;

      .btn.first,
      .btn.last {
        border-radius: 0;
        padding-inline: 12px;
      }

      .btn.last {
        border-right: 0;
      }

      & + & {
        .btn {
          border-left: none;
          padding-inline-start: 12px;
        }
      }
    }

    .react-datepicker-wrapper + .btn,
    .btn:last-child {
      border-radius: 0 var(--btn-radius) var(--btn-radius) 0;
      border-left: 1px solid var(--border-color);
      padding-inline-start: 12px;
    }
  }

  &--edit {
    .react-datepicker-wrapper + .btn {
      border-radius: 0;
      border-left: none;
      padding-inline-start: 12px;
    }
  }

  &--editor {
    --border-color: var(--color-mm-green);
  }
}
