.app {
  padding: 4rem 1rem;
  padding: 0 !important;

  &--noPadding {
    padding: 0 !important;
  }
}

@media screen and (min-width: 360px) {
  .app {
    padding: 4.5rem 2rem;
  }
}

@media screen and (min-width: 96em) {
  .app {
    padding: 4.5rem 1rem;
  }
}
