/* Mixins */
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin font($font) {
  font-family: $font;
  -webkit-text-size-adjust: none;
  /*auto-zoom auf iphone ausschalten*/
  -ms-text-size-adjust: none;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

@mixin bc-transition($time) {
  -webkit-transition: all $time linear;
  -moz-transition: all $time linear;
  -o-transition: all $time linear;
  -ms-transition: all $time linear;
  transition: all $time linear;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);
  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin bg-primary() {
  background-color: $c-bg_primary;
  color: $c-typo_primary;
}

@mixin bg-secondary() {
  background-color: $c-bg_secondary;
  color: $white;
}

@mixin justify-content() {
  @include respond-to('m') {
    align-items: center;
    justify-content: space-between;
  }
}

@mixin position($width) {
  &.graphic--left {
    left: $width/-3;
  }

  &.graphic--left-float {
    left: $width/-8;
  }

  &.graphic--left-pull {
    left: $width/-2;
  }

  &.graphic--left-push {
    left: $width/-4;
  }

  &.graphic--right {
    right: $width/-3;
  }

  &.graphic--right-float {
    right: $width/-8;
  }

  &.graphic--right-pull {
    right: $width/-2;
  }

  &.graphic--right-push {
    right: $width/-4;
  }

  &.graphic--top {
    top: $width/-2;
  }

  &.graphic--top-float {
    top: $width/-3;
  }

  &.graphic--top-push {
    top: $width/-1.3;
  }

  &.graphic--top-pull {
    top: $width/-4;
  }

  &.graphic--bottom {
    bottom: $width/-2;
  }

  &.graphic--bottom-float {
    bottom: $width/-2;
  }

  &.graphic--bottom-push {
    bottom: $width/-1.3;
  }

  &.graphic--bottom-pull {
    bottom: $width/-4;
  }
}
