.login-form {
  display: grid;
  position: relative;
  grid-auto-columns: 1fr max-content max-content;
  grid-template-rows: auto auto;

  grid-template-areas:
    'email email email'
    'password password submit';

  &__input,
  &__btn {
    background-color: var(--color-bg-secondary);
    border: 0;
    padding-block: 1rem 0;
    padding-inline: 1rem 0.4rem;
    width: 100%;
    outline: none;
    vertical-align: bottom;
    position: relative;
    font-weight: 300;
    font-size: 34px;
    line-height: 1.1;
    @include respond-to('m') {
      font-size: 52px;
    }
    @include respond-to('l') {
      font-size: 76px;
      padding-block: 1rem 0.4rem;
    }

    &::placeholder {
      line-height: 1.1;
      font-size: inherit;
      vertical-align: center;
      font-family: inherit;
      color: rgb(117, 117, 117);
    }

    &--email {
      grid-area: email;
      grid-column: 1/-1;
    }

    &--password {
      grid-area: password;
      grid-column: 1/-2;
    }
  }

  &__btn {
    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        opacity: 0.5;
        font-size: 0.9em;
        transition: all ease-in-out 0.3s;
      }

      &:hover {
        svg {
          opacity: 0.8;
        }
      }
    }

    &--submit {
      grid-area: submit;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='85' height='67' fill='none'%3E%3Cpath fill='%23000' d='m51 0 34 33.5L51 67H40l30.4-29.6H0v-7.8h70.4L40 0h11Z'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
      display: block;
      // aspect-ratio: 1;
      font-size: inherit;
      height: 100%;
      width: 6ch;
      @include respond-to('m') {
        background-size: 80%;
      }
      @include respond-to('l') {
      }

      &:hover {
        background-position: 90%;
      }
    }

    &--toggle {
      grid-area: toggle;
    }
  }

  &__status {
    position: absolute;
    overflow: hidden;
    pointer-events: none;
    inset: 0;
    grid-column: 1/-1;
    grid-row: 1/-1;
    z-index: 90;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;

    @include respond-to('s') {
      font-size: 21px;
    }

    @include respond-to('m') {
      font-size: 23px;
    }

    @include respond-to('l') {
      font-size: 24px;
    }
  }

  &__message {
    pointer-events: all;
    position: relative;
    background-color: var(--color-bg-secondary);
    color: var(--color-txt);
    width: 100%;
    height: 100%;
    transform: translate(0, 130%);
    font-size: clamp(20px, 2vw + 0px, 32px);
    transition: all ease-in-out 0.5s;
    padding: 1rem;
    @include respond-to('l') {
      padding: 2rem;
    }
    margin: 0;

    display: grid;
    align-content: center;
    gap: 16px;

    button {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    p {
      display: block;
      text-align: center;
      grid-area: message;
    }

    a {
      display: block;
      font-size: 0.8em;
      text-align: center;
      padding: 0.3em 0.2em 0.2em 0.2em;
      background-color: var(--color-bg-secondary-h);
    }

    &--show {
      transform: translate(0%, 0%);
      transition: all ease-in-out 0.2s;
      pointer-events: visible;
    }

    &--success {
      background-color: var(--color-highlight);
      grid-template-areas: 'message';
    }

    &--error {
      background-color: var(--color-error);
      grid-template-areas:
        'message'
        'btn';
    }
  }

  &__input {
    &--hasError {
      background-color: var(--color-error);

      &,
      &::placeholder {
        color: #000;
      }
    }
  }
}
