.telefon-dienst {
  //width: min(350px, 100% );

  &__list {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    padding: 1rem 0;

    @media (min-width: 64rem) {
      display: grid;
      gap: 16px;
    }
  }

  &__item {
    // overflow: hidden;
    position: relative;
    background: white;
    border-radius: 2px;
    box-shadow:
      rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: grid;
    grid-template-columns: 4px 70px 1fr;

    &:before {
      width: 4px;
      left: 0;
      top: 0;
      bottom: 0;
      content: '';
      //mix-blend-mode: hard-light;
      transform-origin: center;
      background-color: var(--color-mm-green);
    }

    &:hover {
      box-shadow:
        rgba(0, 0, 0, 0.2) 0px 1px 3px,
        rgba(0, 0, 0, 0.44) 0px 1px 2px;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    strong,
    small {
      display: block;
    }

    strong {
      font-size: 1.1em;
    }

    small {
      font-size: 1em;
    }

    border-inline-end: 1px solid var(--color-border);
  }

  &__users {
    padding-block: 1em;
    list-style: none;
    display: grid;
    gap: 0.5em;
    width: 100%;
    padding-inline: 1em;
    min-height: 88px;
  }

  &__user {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: flex-start;

    span {
      flex: 0 1 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      max-width: 140px;
    }

    button {
      display: block;
      flex: 0 0 auto;
      position: relative;
      text-decoration: none;

      &:after {
        content: attr(data-tooltip);
        position: absolute;
        bottom: 0%;
        left: -195%;
        background: #ffcb66;
        padding: 2px 5px;
        color: black;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        white-space: nowrap;
        opacity: 0;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        font-size: 0.5em;
      }

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent #ffcb66;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        opacity: 0;
        left: -12%;
        bottom: 0%;
      }

      &:hover:after {
        bottom: 10%;
      }

      &:hover:before {
        bottom: 20%;
      }

      &:hover:after,
      &:hover:before {
        opacity: 1;
      }
    }
  }
}
