.post-form,
.work-time {
  &__field {
    display: grid;
    gap: 0.75rem;
    align-content: flex-end;

    &--full-w {
      grid-column: 1 /-1;
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 1;

    span {
      color: var(--color-mm-red);
      font-size: 0.7em;
      vertical-align: middle;
    }
  }

  &__input {
    padding: 0.7em 0.5em;
    border-radius: 0.5em;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    line-height: 1;
    background: #f5f5f5;

    &--textarea {
      min-height: 7em;
    }

    &--has-error {
      border: 1px solid var(--color-mm-red);
      color: var(--color-mm-red);
    }
  }

  &__btns {
    grid-column: 1 /-1;
    display: flex;
    gap: 0.5rem;
    pointer-events: none;

    button,
    details {
      pointer-events: all;
    }
  }

  &__btn {
    background-color: var(--color-mm-green);
    border-radius: 8px;
    display: block;

    flex: 1 1 auto;
    padding: 0.25em 1em;
    margin-top: 0.75rem;
    line-height: 1;

    &--close {
      background-color: var(--color-mm-red);
      aspect-ratio: 1;
      justify-content: center;
      align-items: center;
      display: flex;

      svg {
        width: 0.7em;
      }

      flex: 0 0 auto;
    }
  }
}

.post-form {
  display: grid;
  z-index: 19;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

.post-form {
  .action-btn {
    border-radius: 50%;
    font-size: 1.4rem;
    color: var(--color-mm-red);
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgba(0, 0, 0, 0.3);
    border-style: dashed;
    background-color: rgba(250, 250, 250, 0.2);
    color: rgba(189, 189, 189, 0.9);
    outline: none;
    transition: border 0.24s ease-in-out 0s;

    &--accepted {
      border-color: var(--color-mm-green);
      background-color: rgba(6, 214, 160, 0.21);
      color: hsl(164, 95%, 5%);
    }

    &--rejected {
      border-color: var(--color-mm-red);
      background-color: rgba(231, 90, 124, 0.21);
      color: hsl(346, 75%, 5%);
    }

    &--focused {
      border-color: var(--color-mm-blue);
      background-color: rgba(55, 114, 255, 0.21);
      color: hsl(222, 100%, 10%);
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 0.5rem;
    position: relative;
    width: 100%;
  }

  &__image {
    width: 100%;
    max-height: 125px;
    position: relative;
    border-radius: 5px;
    position: relative;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    &:hover {
      .post-form__overlay {
        transform: scale(1);
      }
    }
  }

  &__overlay {
    position: absolute;
    inset: 0;
    background: rgba(57, 57, 57, 0.702);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 9998;
    transform: scale(0);
  }
}

.work-time {
  display: block;
}

.task-form {
  display: flex;
  width: 100%;

  &__input {
    padding: 0.7em 0.5em;
    border-radius: 0.5em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    line-height: 1;
    color: black;
    background: #f5f5f5;

    &--has-error {
      border: 1px solid var(--color-mm-red);
      color: var(--color-mm-red);
    }
  }

  &__btn {
    flex: 0 0 auto;
    background-color: var(--color-mm-green);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: block;

    flex: 1 1 auto;
    padding: 0.25em 1em;
    line-height: 1;
    // letter-spacing: 0.15em;
    &--close {
      background-color: var(--color-mm-red);
      aspect-ratio: 1;
      justify-content: center;
      align-items: center;
      display: flex;

      svg {
        width: 0.7em;
      }

      flex: 0 0 auto;
    }
  }
}
