@keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 80px 0;
  }
}

.btn {
  --btn-radius: 4px;
  --btn-color: var(--color-mm-green);
  --btn-color-txt: white;
  display: inline-flex;
  outline: none;
  place-items: center;
  padding: 8px 24px;

  font-size: 14px;
  line-height: 1.21;
  border-radius: var(--btn-radius);
  transition: background-color 0.4s;
  cursor: pointer;
  border: 1px solid transparent;

  &--loading {
    background-image: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.2) 25%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0)
    );
    background-size: 80px 80px;
    animation: loading 1.5s linear infinite;

    &:before {
      content: '';
    }
  }

  &--color-green {
    --btn-color: var(--color-mm-green);
    --btn-hover-color: var(--color-mm-green-active);
    --btn-color-txt: white;
  }

  &--color-blue {
    --btn-color: var(--color-mm-blue);
    --btn-hover-color: var(--color-mm-blue-active);
    --btn-color-txt: white;
  }

  &--color-red {
    --btn-color: var(--color-mm-red);
    --btn-hover-color: var(--color-mm-red-active);
    --btn-color-txt: white;
  }

  &--color-yellow {
    --btn-color: var(--color-mm-yellow);
    --btn-hover-color: var(--color-mm-yellow-active);

    --btn-color-txt: white;
  }

  &--color-gray {
    --btn-color: var(--color-mm-gray);
    --btn-color-txt: black;
  }

  &:hover {
    background-color: var(--btn-hover-color);
  }

  &--default {
    background-color: var(--btn-color);
    color: var(--btn-color-txt);
  }

  &--outline {
    background-color: #f8f8f8;
    border-color: var(--btn-color);
    color: var(--btn-color);

    &:hover {
      background-color: #f8f8f8;
      border-color: var(--btn-hover-color);
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &[disabled]:not(.btn--loading) {
    opacity: 0.7;
  }

  &--icon {
    padding: 8px 10px;

    svg {
      width: 1.1em;
      height: 1.1em;
      fill: currentcolor;
    }
  }
}

.btn-group > .btn {
  padding-inline: 12px;

  &:first-child {
    border-left-width: 2px;
    border-radius: var(--btn-radius) 0 0 var(--btn-radius);
  }

  &:last-child {
    border-radius: 0 var(--btn-radius) var(--btn-radius) 0;
  }
}

.btn-group {
  display: flex;

  .btn {
    &--outline {
      & + & {
        border-left: none;
      }
    }
  }
}
