.content-editor {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  &__content {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__sidebar {
    flex-grow: 1;
    flex-basis: 20rem;
    flex-grow: 1;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__field {
    display: grid;
    gap: 0.75rem;
  }

  &__label {
    display: flex;
    justify-content: space-between;

    span {
      display: block;
    }

    .error {
      color: var(--color-mm-red);
    }
  }

  &__dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1.25rem;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgba(0, 0, 0, 0.3);
    border-style: dashed;
    background-color: rgba(250, 250, 250, 0.2);
    color: rgba(189, 189, 189, 0.9);
    outline: none;
    transition: border 0.24s ease-in-out 0s;

    &--accepted {
      border-color: var(--color-mm-green);
      background-color: rgba(6, 214, 160, 0.21);
      color: hsl(164, 95%, 5%);
    }

    &--rejected {
      border-color: var(--color-mm-red);
      background-color: rgba(231, 90, 124, 0.21);
      color: hsl(346, 75%, 5%);
    }

    &--focused {
      border-color: var(--color-mm-blue);
      background-color: rgba(55, 114, 255, 0.21);
      color: hsl(222, 100%, 10%);
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 0.5rem;
    position: relative;
    width: 100%;
  }

  &__input {
    padding: 0.7em 0.5em;
    border-radius: 0.5em;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    line-height: 1;
    background: #f5f5f5;

    &--has-error {
      border: 1px solid var(--color-mm-red);
      color: var(--color-mm-red);
    }
  }

  &__image-wrapper {
    position: relative;
    max-width: 100%;

    &--selected {
      border: 2px solid var(--color-mm-blue);
      box-shadow: 0 0 0 3px #b4d5ff;
    }

    &:hover {
      .content-editor__image-overlay {
        transform: scale(1);
      }
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 20em;
  }

  &__image-overlay {
    position: absolute;
    inset: 0;
    background: rgba(57, 57, 57, 0.702);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 9998;
    transform: scale(0);

    button {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      background-color: white;
    }
  }
}
