.margrit {
  &-editor {
    max-width: auto;

    &__button {
      cursor: pointer;
      color: #ccc;

      &--active {
        color: black;
      }

      &--reversed {
        color: #aaa;

        &.margrit-editor__button--active {
          color: white;
        }
      }
    }

    &__icons {
      font-size: 18px;
      vertical-align: text-bottom;
    }

    &__menu {
      display: flex;
      gap: 0.5rem;

      & > * {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 4px;
        border: 1px solid rgba(204, 204, 204, 0.5);
      }
    }

    &__toolbar {
      position: relative;
      padding: 8px 12px;
      border-block: 2px solid #eee;
      margin-block: 20px;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 99999;
    }

    &__image {
      position: relative;

      img {
        display: block;
        max-width: 100%;
        max-height: 20em;
        box-shadow: none;
      }

      &--selected img {
        box-shadow: 0 0 0 3px #b4d5ff;
      }
    }

    &__hovering-toolbar {
      padding: 8px 7px 6px;
      position: absolute;
      z-index: 1;
      top: -10000px;
      left: -10000px;
      margin-top: -6px;
      opacity: 0;
      background-color: #222;
      border-radius: 4px;
      transition: opacity 0.75s;
    }

    &__textarea {
      border-inline-end: 2px solid #eee;
      max-width: initial;
      margin-top: 0;
    }
  }
}

.chat-interface {
  // max-width: 90ch;
  display: flex;
  flex-direction: column;
  height: 100%;

  .markdown {
    .codeStyle,
    code,
    code span {
      font-size: 0.9em;
      line-height: 1.25;
    }

    pre {
      background: transparent;
    }

    ul,
    ol {
      list-style-position: inside;
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      color: #000;
      border: none;
      @include text;
    }

    table thead th,
    table tfoot th {
      color: #000;
    }

    table caption {
      padding: 0.5em;
    }

    table th,
    table td {
      text-align: left;
      padding: 0.5em;
      border-bottom: 1px solid #ddd;
    }
  }

  &__messages {
    display: grid;
    grid-template-columns: 1;
    grid-gap: 1rem;
  }

  &__message {
    display: flex;
    flex-direction: column;
    max-width: 80%;

    .role {
      padding: 1rem;
    }

    .text {
      white-space: pre-line;
      padding: 1rem;
      max-width: 65ch;
      border-radius: 16px;
      background-color: #fafafa;
    }

    &--user {
      align-items: flex-end;
      margin-left: auto;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;

    &-text {
      width: 100%;
      background-color: #fafafa;
      border-radius: 20px;
      padding: 1rem 1rem;
      margin-right: 1rem;
      resize: none;
      outline: none;
      @include text;

      &::placeholder {
        color: #ccc;
      }
    }

    &-button {
      color: var(--color-mm-green);
      padding: 1rem 1rem;
    }
  }

  .clamped-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .scroll-stack {
    gap: 1rem;
    padding: 2rem 0;

    @include respond-to('m') {
      gap: 2rem;
      flex-wrap: wrap;
    }
  }

  &__prompt-example {
    color: var(--color-mm-green);
    font-weight: 400;
    padding: 1rem 0.5rem;
    white-space: nowrap;
    -webkit-appearance: none;
    background-color: #fafafa;
    border-radius: 8px;
    width: 36%;
    text-decoration: none;

    @include respond-to('m') {
      width: 22%;
      padding: 1rem;
    }

    &:hover,
    &:active {
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }

  &__chat-reset {
    color: var(--color-mm-red);
    font-weight: 400;
    padding: 1rem;
    white-space: nowrap;
    background: transparent;
    -webkit-appearance: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 16px;
    overflow: hidden;

    img {
      object-fit: cover;
      border-radius: 16px;
      overflow: hidden;
    }
  }

  .loader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  svg {
    width: 40px;
    transform-box: fill-box;

    circle {
      fill: #ccc;
      transform-origin: center center;
      animation: loader 1s infinite ease-in-out;
    }

    circle:nth-child(2) {
      animation-delay: -0.3s;
    }

    circle:last-child {
      animation-delay: -0.6s;
    }
  }

  @keyframes loader {
    0%,
    100% {
      transform: scale(0.75);
    }
    50% {
      transform: scale(1);
    }
  }
}
