.dropdown-root {
  --dd-fs: 12px;
  border: none;
  font-size: var(--dd-fs);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &--default {
    border-radius: 6px;
    border: 1px solid #d7dce5;
    padding: 6px var(--dd-fs);
  }

  &--icon {
    padding: 6px;
    border-radius: 6px;
  }

  &--open,
  &:hover {
    background: #d7dce5;
  }
}

.menu {
  --dd-fs: 12px;
  background: white;
  border: 1px solid #d7dce5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(#404040, 0.15);
  outline: 0;
  width: max-content;

  &__item {
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: white;
    border: none;
    border-radius: 4px;
    font-size: var(--dd-fs);
    text-align: left;
    line-height: 1.8;
    min-width: 110px;
    margin: 0;
    outline: 0;

    & + & {
      border-top: 1px solid rgba(215, 220, 229, 0.4);
    }

    &--open {
      background: #d7dce5;
    }

    &:focus,
    &:not([disabled]):active {
      background-color: #f1f3f7;
    }
  }
}
