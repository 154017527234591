@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.markdown {
  font-weight: 400;
  line-height: var(--markdown-line-height, 1.75);
  font-size: var(--markdown-font-size, 1.5rem);

  p + p {
    margin-top: 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1.38em 0 1.08em;

    line-height: 1.3;
  }

  h1 {
    margin-top: 0;
    font-size: 1.7em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 1.025em;
  }

  small,
  .text_small {
    font-size: 0.889em;
  }

  strong {
  }

  pre,
  code {
    font-family: Menlo, Monaco, 'Courier New', monospace;
  }

  pre {
    background-color: #fafafa;
    font-size: 0.8em;
    position: relative;

    .react-syntax-highlighter-line-number {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  p + pre {
    margin-top: 1.2em;
  }

  pre + p {
    margin-top: 1.2em;
  }

  a,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: var(--color-mm-green);
  }

  ul {
    padding-left: 1em;
  }

  .codeStyle,
  code,
  code span {
    font-size: 0.9em;
    line-height: 1.25;
  }
}

.markdown--small {
  --markdown-font-size: 1rem;
  --markdown-line-height: 1.2;
}

.markdown--medium {
  --markdown-font-size: 1.25rem;
  --markdown-line-height: 1.5;
}

.markdown--large {
  --markdown-font-size: 1.5rem;
  --markdown-line-height: 1.75;
}

.markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
}

.md-editor {
  &__toolbar {
    position: absolute;
    z-index: 1;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    opacity: 0;
    background-color: #222;
    border-radius: 4px;
    transition: opacity 0.75s;
    display: flex;
  }

  &__btn {
    padding: 0.5em 0.5em;

    & + & {
      border-inline-start: 1px solid rgba(204, 204, 204, 0.2);
    }

    svg {
      fill: #fff;
      width: 1em;
      height: 1em;
    }

    &--active {
      background-color: rgba(204, 204, 204, 0.2);
    }
  }
}
