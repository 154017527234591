$barCyan: hsl(186, 34%, 60%);

$darkBrown: hsl(25, 47%, 15%);
$MediumBrown: hsl(28, 10%, 53%);
summary {
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
}

.time-tracking-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
}

.tt-combobox {
  list-style: none;
  position: relative;

  &__input-wrapper {
    position: relative;
  }

  &__input,
  &__btn {
    background-color: var(--color-bg-secondary);
    border: 0;
    outline: none;
    vertical-align: bottom;
    position: relative;
    font-size: 16px;
    line-height: 1.5;

    &::placeholder {
      line-height: 1.1;
      font-size: inherit;
      vertical-align: center;
      font-family: inherit;
      color: rgb(117, 117, 117);
    }

    &--email {
      grid-area: email;
      grid-column: 1/-1;
    }

    &--password {
      grid-area: password;
      grid-column: 1/-2;
    }
  }

  &__input {
    padding-block: 1rem 0;
    padding-inline: 1rem 1.4rem;
    width: 100%;
  }

  & &__btn {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 2ch;
    padding: 0;
    bottom: 0;
  }

  &__options {
    background-color: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0 0 4px 4px;
    display: block;
    max-height: 15rem;
    overflow-y: auto;
    display: grid;
    width: 100%;
    z-index: 100;
    gap: var(--gap);
  }

  &__title {
    font-family: 'Theinhardt', sans-serif;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.1;
  }

  &__option {
    padding-inline: var(--gap);
  }

  &__customer {
    color: rgba(black, 0.5);
    text-transform: uppercase;
    font-size: 0.8em;
    font-family: 'Theinhardt', sans-serif;

    display: block;
    margin-bottom: 0.4em;
  }
}
