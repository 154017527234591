.time-tracking {
  max-width: 1200px;
  margin: 0 auto;
  --border: 1px solid var(--color-mm-gray-3);
  --padding-inline: clamp(16px, 3vw, 32px);
  display: grid;
  grid-template-columns: 320px 1fr;
  min-height: 100vh;

  &__headline {
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 48px;
    padding-inline: var(--padding-inline);
  }

  &__text {
    font-size: 14px;
    line-height: 1.21;
  }

  &__tracker,
  &__entry,
  &__overview > &__title,
  &__aside > &__title,
  &__summaries,
  &__manual,
  &__edit {
    padding-inline: var(--padding-inline);
  }

  &__title,
  summary {
    flex: 0 0 100%;

    font-size: 14px;
    line-height: 1.21;
  }

  &__tracker {
    border-bottom: var(--border);
    padding-block: 24px;
    display: grid;
    grid-template-columns: 64px 1fr;
    gap: 16px;
    align-content: center;
    align-items: center;
    position: relative;

    .btn {
      grid-column: 1 / -1;
      justify-content: center;
    }
  }

  &__overview,
  &__aside {
    padding-block-start: 4rem;
    @media screen and (min-width: 360px) {
      padding-block-start: 4.5rem;
    }
  }

  &__aside {
    border-right: var(--border);
  }

  &__btn {
    width: 64px;
    height: 64px;
    display: block;
    border-radius: 50%;
    background-color: rgb(248, 248, 248);
    color: var(--color-mm-green);

    &:hover {
      background-color: var(--color-mm-gray-3);
      color: var(--color-mm-green-active);
    }
  }

  &__summaries {
    padding-block: 16px;
    display: grid;
    gap: 8px;
  }

  &__summary {
    summary {
      padding: 16px;
      background-color: var(--color-mm-gray-3);
      cursor: pointer;
      transition: all 0.3s ease-out;
      display: grid;
      grid-template-columns: 1fr auto 32px;
      gap: 32px;
      justify-content: space-between;
      align-items: center;
      color: black;
      line-height: 0;

      span {
        font-size: 14px;
        line-height: 1.21;

        &.date {
        }

        &.time {
          font-variant-numeric: tabular-nums;
        }
      }

      svg {
        transition: all 0.3s ease-out;
        transform: rotate(0deg);
      }
    }

    &[open] {
      summary {
        background-color: var(--color-mm-gray-2);
        color: white;
        fill: white;

        svg {
          color: white;
          transform: rotate(180deg);
        }
      }
    }

    &--empty,
    &--future {
      summary {
        background-color: transparent;
        border: 1px solid #f2f2f2;

        .time {
          opacity: 0;
        }
      }
    }

    &--empty {
      svg {
        opacity: 0;
      }

      &[open] {
        summary {
          background-color: transparent;
          color: black;
          fill: black;

          svg {
            opacity: 0;
          }
        }
      }
    }

    &--future {
      summary {
        color: #f2f2f2;
        pointer-events: none;
      }
    }

    &--today,
    &--today[open] {
      summary {
        background-color: var(--color-mm-green);
        color: white;
        fill: white;
      }
    }
  }

  &__entry {
    border-bottom: var(--border);
    padding-block: 24px;
    display: grid;
    grid-template-columns: 1fr 32px;
    gap: 8px 8px;

    h3,
    p {
      grid-column: 1 / span 1;
    }

    p {
      margin-top: 4px;

      font-size: 14px;
      line-height: 1.21;
      display: flex;
      justify-content: space-between;

      span {
        display: block;
      }

      span + span {
      }
    }

    input {
      outline: 1px solid var(--color-mm-gray-3);
    }

    &--inline {
      display: grid;
      grid-template-columns: 1fr auto 32px;
      gap: 8px 32px;
      align-items: center;
      padding: 16px;

      h3,
      p,
      .time-tracking__action {
        grid-column: span 1;
      }

      p {
        gap: 16px;
        margin: 0;
        align-content: flex-start;

        span {
          font-variant-numeric: tabular-nums;
        }
      }
    }

    &--reversed {
      p {
        flex-direction: row-reverse;

        span {
        }

        span + span {
        }
      }
    }
  }

  &__action {
    pointer-events: all;
    position: relative;
    grid-column: span 1/ -1;
  }

  &__manual,
  &__edit {
    width: 100%;
    display: grid;
    padding-block: 12px 24px;
    z-index: 19;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;
    gap: 0.75rem;
    border-bottom: var(--border);
  }

  &__manual {
    grid-template-rows: repeat(2, 1fr) auto;
  }

  &__edit {
    grid-template-rows: repeat(2, auto);
  }
}

.native-datepicker {
  display: block;
  background: #f2f2f2;
  border-radius: 4px;
  outline: none;
  padding: 1px 0.5rem;
}
