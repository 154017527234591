.MacSelect {
  background: white;
  font-size: 14px;
  box-shadow:
    rgb(0 0 0 / 50%) 0px 0px 1px,
    rgb(0 0 0 / 3%) 0px 100px 80px,
    rgb(0 0 0 / 2%) 0px 41.7776px 33.4221px,
    rgb(0 0 0 / 2%) 0px 22.3363px 17.869px,
    rgb(0 0 0 / 4%) 0px 12.5216px 10.0172px;
  box-sizing: border-box;
  overflow-y: auto;
  overscroll-behavior: contain;
  scrollbar-width: none;
  outline: 0;
  user-select: none;
  width: 130px;
}

.MacSelect-button {
  all: unset;
  font-size: 15px;
  padding: 12px 10px;
  line-height: 1;
  background: white;
  border-radius: 5px;
  cursor: default;
  user-select: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
  font-variant-numeric: tabular-nums;

  &:hover {
    background: rgba(0, 200, 255, 0.2);
    border-color: transparent;
  }

  &:focus-visible {
    border-color: royalblue;
  }
}

.MacSelect {
  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    display: block;
    all: unset;
    font-variant-numeric: tabular-nums;
    text-align: center;
    width: 100%;
    padding: 12px 10px;
    box-sizing: inherit;
    user-select: none;
    line-height: 1;
    background-color: transparent;
    scroll-margin: 50px;

    &--highlight {
      background-color: rgba(5, 214, 158, 0.75);
    }

    &--selected {
      background-color: var(--color-mm-blue);
      color: white;
      font-weight: bold;
    }

    &--active {
      background-color: var(--color-mm-blue-active);
    }
  }
}

.MacSelect-ScrollArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  cursor: default;
  font-size: 12px;
  line-height: 1;
  height: 25px;
  user-select: none;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 35px;
    position: absolute;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }

  &[data-dir='up']::before {
    background: linear-gradient(to bottom, white 50%, transparent);
    border-radius: 8px 8px 0 0;
    top: 0;
  }

  &[data-dir='down']::before {
    background: linear-gradient(to top, white 50%, transparent);
    border-radius: 0 0 8px 8px;
    top: -10px;
  }
}
