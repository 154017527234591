.edit-project-card {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'color name btns'
    'color customer btns';

  &__color,
  &__name,
  &__customer {
    padding: 8px;
  }

  &__color {
    grid-area: color;
    border-right: 1px solid rgba(black, 0.2);
    align-self: stretch;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;

    input {
      width: 100%;
      height: 100%;
      mix-blend-mode: hard-light;
      border-radius: 15px;
    }
  }

  &__name {
    grid-area: name;

    input {
      font-family: 'Theinhardt', sans-serif;
      margin: 0;
      font-size: 1.1rem;
      line-height: 1.1;

      outline: none;
      width: 100%;
    }
  }

  &__customer {
    border-top: 1px solid rgba(black, 0.2);
    grid-area: customer;
    color: rgba(black, 0.65);
    text-transform: uppercase;
    font-size: 1rem;
    font-family: 'Theinhardt', sans-serif;
    font-weight: 300;
    display: block;

    select {
      width: 100%;
    }
  }

  &__btns {
    grid-area: btns;
    border-left: 1px solid rgba(black, 0.2);
    display: grid;
    align-self: stretch;

    button {
      padding: 8px;
    }

    button + button {
      border-top: 1px solid rgba(black, 0.2);
    }
  }
}
